import {Injectable} from '@angular/core';
import {Client} from '../models/client.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReservationStatuses} from '../models/reservation-statuses';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    private _emailApiUri = 'https://api.elasticemail.com/v2/email/send';

    constructor(private _httpClient: HttpClient) {
    }

    emailClient(client: Client, reservationStatus: ReservationStatuses) {
        const httpParams = new HttpParams()
            .append('apiKey', '4fe41339-7557-4c62-9b4e-d72135ecfc94')
            .append('subject', 'Hotel Booking Update')
            .append('from', 'info@imanageholdings.com')
            .append('to', `${client.email}`)
            .append('bodyText', `Hi ${client.fname},\n\nYour booking is ${reservationStatus.toLowerCase()}\n\n`);
        return this._httpClient.post(this._emailApiUri, null, {params: httpParams})
            .subscribe(() => {
            });
    }

    emailInvoiceToClient(fileName: string, file: Blob, client: any) {
        const formData = new FormData();
        formData.append(fileName, file);
        const httpParams = new HttpParams()
            .append('apiKey', '4fe41339-7557-4c62-9b4e-d72135ecfc94')
            .append('subject', 'Hotel Booking Update')
            .append('from', 'info@imanageholdings.com')
            .append('to', `${client.email}`)
            .append('bodyText', `Hi ${client.fname},\n\nPlease find attached your invoice\n\n`);
        return this._httpClient.post(this._emailApiUri, formData, {params: httpParams})
            .subscribe(() => {
            });
    }
}
