
import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';
import { RoomType } from '../app/models/room-type';

@Injectable()
export class RoomTypesService {
    roomtypes: AngularFirestoreCollection<RoomType>;
    private RoomTypesDoc: AngularFirestoreDocument<RoomType>;

    constructor(private afs: AngularFirestore) {
        //Get the collection
        this.roomtypes = afs.collection<RoomType>('roomtype');
    }

    addRoomTypes(resdata) {
        this.roomtypes.add(resdata);
    }

    updateRoomTypes(id, update) {
        this.RoomTypesDoc = this.afs.doc<RoomType>(`${'roomtype'}/${id}`);
        this.RoomTypesDoc.update(update);
    }

    deleteRoomTypes(id) {
        this.RoomTypesDoc = this.afs.doc<RoomType>(`${'roomtype'}/${id}`);
        this.RoomTypesDoc.delete();
    }
}