import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Amenity } from '../app/models/amenity';


@Injectable()
export class AmenitiesService {
    amenitys: AngularFirestoreCollection<Amenity>;
    private amenitysDoc: AngularFirestoreDocument<Amenity>;

    constructor(private afs: AngularFirestore) {
        //Get the collection
        this.amenitys = afs.collection<Amenity>('amenity');
    }

    addamenitys(resdata) {
        this.amenitys.add(resdata);
    }

    updateamenitys(id, update) {
        this.amenitysDoc = this.afs.doc<Amenity>(`${'amenity'}/${id}`);
        this.amenitysDoc.update(update);
    }

    deleteamenitys(id) {
        this.amenitysDoc = this.afs.doc<Amenity>(`${'amenity'}/${id}`);
        this.amenitysDoc.delete();
    }
}