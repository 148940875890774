import { Settings } from '../app/models/settings.model';
import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';

@Injectable()
export class SettingsService {
    settings: AngularFirestoreCollection<Settings>;
    private SettingsDoc: AngularFirestoreDocument<Settings>;

    constructor(private afs: AngularFirestore) {
        //Get the tasks collection
        this.settings = afs.collection<Settings>('settings');
    }

    // updateSettings(id, update) {
    //     this.SettingsDoc = this.afs.doc<Settings>(`${'settings'}/${id}`);
    //     this.SettingsDoc.update(update);
    //
    //     // this.afs.doc<Settings>(`${'settings'}/${id}`)
    //     // this.SettingsDoc.update(update);
    // }

    updateSettings(id, update){
        return this.afs.collection('settings').doc(id).set(update);
    }

    // updateUser(userKey, value){
    //     value.nameToSearch = value.name.toLowerCase();
    //     return this.db.collection('users').doc(userKey).set(value);
    // }

}