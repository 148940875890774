import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ThemeComponent} from './theme/theme.component';
import {LayoutModule} from './theme/layouts/layout.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ScriptLoaderService} from "./_services/script-loader.service";
import {ThemeRoutingModule} from "./theme/theme-routing.module";
import {AuthModule} from "./auth/auth.module";

import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireModule} from '@angular/fire';
import {AuthService} from '../firebase-services/auth.service';
import {ClientService} from '../firebase-services/clients.service';
import {ReservationsService} from '../firebase-services/reservations.service';
import {PropertyService} from '../firebase-services/properties.service';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {SettingsService} from '../firebase-services/settings.service';
import {RoomTypesService} from '../firebase-services/roomtypes.service';
import {StayTypesService} from '../firebase-services/staytypes.service';
import {AmenitiesService} from '../firebase-services/amenities.service';
import {RemindersService} from "./_services/reminders.service";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {EmailService} from './_services/email.service';
import {HttpClientModule} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DateFormatPipe } from './pipes/date-format.pipe';
import {NumbersOnlyDirective} from "./_directives/numbers-only.directive";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        DateFormatPipe,
        NumbersOnlyDirective,
        ThemeComponent
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        FormsModule,
        AngularFireMessagingModule,
        AngularFireDatabaseModule,
        AngularFireModule.initializeApp(environment.firebase),
        HttpClientModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        ReactiveFormsModule],
    providers: [ScriptLoaderService,
        AuthService,
        ClientService,
        ReservationsService,
        PropertyService,
        SettingsService,
        RoomTypesService,
        StayTypesService,
        AmenitiesService,
        RemindersService,
        EmailService,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
        window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
}).catch(err => console.error(err));
