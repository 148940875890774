import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';

import {Observable} from 'rxjs';
import {of} from 'rxjs/observable/of';
import {switchMap} from 'rxjs/operators';
import {User} from '../app/models/user';


@Injectable()
export class AuthService {

    user: Observable<User>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {

        //// Get auth data, then get firestore user document || null
        this.user = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
                } else {
                    return of(null)
                }
            })
        )
    }
    //// Email/Password Auth ////

    emailSignUp(email: string, password: string) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
            .then(user => {
                return this.setUserDoc(user) // create initial user document
            })
            .catch(error => this.handleError(error));
    }

    emailLogin(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then((user) => {
                //this.user = user
            })
            .catch(error =>{
                alert('Invalid Username / Password...!');
                window.location.href = '/login';
            });
    }

    // Update properties on the user document
    updateUser(user: User, data: any) {
        return this.afs.doc(`users/${user.id}`).update(data)
    }

    logout() {
        return this.afAuth.auth.signOut()
            .then((user) => {
                //this.user = user
                this.router.navigate['/login']
            })
            .catch(error => console.log(error));
    }



    // If error, console log and notify user
    private handleError(error) {
        console.error(error)
        alert(error.message);
    }

    // Sets user data to firestore after succesful login
    private setUserDoc(user) {
        console.log(user.user.uid);
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.user.uid}`);

        const data: User = {
            uid: user.user.uid,
            email: user.user.email || null,
            photoURL: 'https://goo.gl/Fz9nrQ'
        }

        return userRef.set(data)

    }
}