
import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';
import { StayType } from '../app/models/staytypes.model';


@Injectable()
export class StayTypesService {
    staytypes: AngularFirestoreCollection<StayType>;
    private StayTypesDoc: AngularFirestoreDocument<StayType>;

    constructor(private afs: AngularFirestore) {
        //Get the collection
        this.staytypes = afs.collection<StayType>('staytype');
    }

    addStayTypes(resdata) {
        this.staytypes.add(resdata);
    }

    updateStayTypes(id, update) {
        this.StayTypesDoc = this.afs.doc<StayType>(`${'staytype'}/${id}`);
        this.StayTypesDoc.update(update);
    }

    deleteStayTypes(id) {
        this.StayTypesDoc = this.afs.doc<StayType>(`${'staytype'}/${id}`);
        this.StayTypesDoc.delete();
    }
}