import { Room } from '../app/models/room';
import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';

@Injectable()
export class PropertyService {
    properties: AngularFirestoreCollection<Room>;
    private PropertyDoc: AngularFirestoreDocument<Room>;

    constructor(private afs: AngularFirestore) {
        //Get the tasks collection
        this.properties = afs.collection<Room>('property');
    }

    addProperty(propertydata) {
        this.properties.add(propertydata);
    }

    updateProperty(id, update) {
        this.PropertyDoc = this.afs.doc<Room>(`${'property'}/${id}`);
        this.PropertyDoc.update(update);
    }

    deleteProperty(id) {
        this.PropertyDoc = this.afs.doc<Room>(`${'property'}/${id}`);
        this.PropertyDoc.delete();
    }
}