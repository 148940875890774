import { Client } from '../app/models/client.model';
import { Injectable } from '@angular/core';
import {
    AngularFirestoreDocument,
    AngularFirestore,
    AngularFirestoreCollection
} from '@angular/fire/firestore';

@Injectable()
export class ClientService {
    clients: AngularFirestoreCollection<Client>;
    private ClientDoc: AngularFirestoreDocument<Client>;

    constructor(private afs: AngularFirestore) {
        //Get the tasks collection
        this.clients = afs.collection<Client>('clients');
    }

    addClient(clientdata) {
        this.clients.add(clientdata);
    }

    updateClient(id, update) {
        this.ClientDoc = this.afs.doc<Client>(`${'clients'}/${id}`);
        this.ClientDoc.update(update);
    }

    deleteClient(id) {
        this.ClientDoc = this.afs.doc<Client>(`${'clients'}/${id}`);
        this.ClientDoc.delete();
    }
}
