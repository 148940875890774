import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../auth/_guards";

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],
        "children": [
            {
                "path": "calendar",
                "loadChildren": ".\/pages\/default\/calendar\/calendar.module#CalendarModule"
            },
            {
                "path": "clients",
                "loadChildren": ".\/pages\/default\/clients\/clients.module#ClientsModule"
            },
            {
                "path": "property",
                "loadChildren": ".\/pages\/default\/properties\/properties.module#PropertiesModule"
            },
            {
                "path": "reservations",
                "loadChildren": ".\/pages\/default\/reservations\/reservations.routing.module#ReservationsRoutingModule"
            },
            {
                "path": "settings",
                "loadChildren": ".\/pages\/default\/settings\/settings.module#SettingsModule"
            },
            {
                "path": "integrations",
                "loadChildren": ".\/pages\/default\/integrations\/integrations.module#IntegrationsModule"
            },
            {
                "path": "reminders",
                "loadChildren": ".\/pages\/default\/reminders\/reminders.module#RemindersModule"
            },
            {
                "path": "room-cleaning",
                "loadChildren": ".\/pages\/default\/Sroomcleaning\/Sroomcleaning.module#SroomcleaningModule"
            },
            {
                "path": "room-pricing",
                "loadChildren": ".\/pages\/default\/RoomTypesPricing\/RoomTypesPricing.module#RoomtypesPricingModule"
            },
            {
                "path": "accounting",
                "loadChildren": ".\/pages\/default\/accounting\/accounting.module#AccountingModule"
            },
            {
                "path": "index",
                "loadChildren": ".\/pages\/default\/dashboard\/dashboard.module#DashboardModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "snippets\/pages\/user\/login-1",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/user\/user-login-1\/user-login-1.module#UserLogin1Module"
    },
    {
        "path": "snippets\/pages\/user\/login-2",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/user\/user-login-2\/user-login-2.module#UserLogin2Module"
    },
    {
        "path": "snippets\/pages\/user\/login-3",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/user\/user-login-3\/user-login-3.module#UserLogin3Module"
    },
    {
        "path": "snippets\/pages\/user\/login-4",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/user\/user-login-4\/user-login-4.module#UserLogin4Module"
    },
    {
        "path": "snippets\/pages\/user\/login-5",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/user\/user-login-5\/user-login-5.module#UserLogin5Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-1",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-1\/errors-error-1.module#ErrorsError1Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-2",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-2\/errors-error-2.module#ErrorsError2Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-3",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-3\/errors-error-3.module#ErrorsError3Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-4",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-4\/errors-error-4.module#ErrorsError4Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-5",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-5\/errors-error-5.module#ErrorsError5Module"
    },
    {
        "path": "snippets\/pages\/errors\/error-6",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-6\/errors-error-6.module#ErrorsError6Module"
    },
    {
        "path": "**",
        "redirectTo": "login",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThemeRoutingModule { }