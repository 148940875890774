export enum ReservationStatuses {
    Open = 'Open',
    Completed = 'Completed',
    Deleted = 'Deleted',
    Invoiced = 'Invoiced',
    PreReservation= 'Pre-Reservation',
    Cancelled= 'Cancelled',
    InProgress= 'In-Progress',
    Closed= 'Closed',
}
