import {Reservation} from '../app/models/reservation.model';
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ReservationStatuses} from "../app/models/reservation-statuses";
import * as firebase from 'firebase';
import {EmailService} from '../app/_services/email.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {ToastrService} from 'ngx-toastr';
import {Client} from '../app/models/client.model';
import {RoomCleanStatuses} from '../app/models/room-clean-statuses';
import {PropertyService} from './properties.service';

@Injectable()
export class ReservationsService {
    reservations: AngularFirestoreCollection<Reservation>;
    private ReservationsDoc: AngularFirestoreDocument<Reservation>;
    private clients: Client[];

    constructor(private _firestore: AngularFirestore,
                private _emailService: EmailService,
                private _fireAuth: AngularFireAuth,
                private _toastrService: ToastrService,
                private propertyService: PropertyService
    ) {
        this.reservations = _firestore.collection<Reservation>('reservation');

        this._firestore.collection('clients').snapshotChanges()
            .map(actions =>
                actions.map(action => ({id: action.payload.doc.id, ...(action.payload.doc.data())})))
            .subscribe(
                (client: Client[]) => {
                    this.clients = client;
                }
            );
    }

    create(result) {
        this._firestore.collection('reservation').add({
            reservationType: result.reservationType,
            resid: result.reservation,
            roomId: result.room,
            clientId: result.client,
            clientGroupId: result.clientGroup,
            created: result.created,
            from: result.checkIn,
            to: result.checkOut,
            status: result.status,
            amenities: result.amenities,
            paymentType: result.paymentType,
            pricing: result.pricing,
            notes: result.notes,
            stayType:result.stayType
        }).then(() => {
            this._firestore.collection('reservation-logs').add({
                date: new Date(),
                user: this._fireAuth.auth.currentUser.email,
                action: `Create reservation ${result.reservation}`
            }).then(() => {
                },
                (reason) => this._toastrService.error('', reason.message)
            ).catch(reason => this._toastrService.error('', reason.message));
            const client = this.clients.find(c => c.id === result.client);
            this._emailService.emailClient(client, result.status);
            this._toastrService.success('', 'Reservation created');
        },);
    }

    update(reservation: Reservation, result: any) {
        this._firestore.collection('reservation').doc(reservation.id).update({
            reservationType: result.reservationType,
            roomId: result.room,
            clientId: result.client,
            from: result.checkIn,
            to: result.checkOut,
            status: result.status,
            amenities: result.amenities,
            paymentType: result.paymentType,
            pricing: result.pricing,
            notes: result.notes
            // stayType:result.stayType
        }).then(() => {
            const client = this.clients.find(c => c.id === result.client);
            this._emailService.emailClient(client, result.status);
            this._firestore.collection('reservation-logs').add({
                date: new Date(),
                user: this._fireAuth.auth.currentUser.email,
                action: `Update reservation ${reservation.resid}`
            }).then(() => {
                },
                (reason) => this._toastrService.error('', reason.message)
            ).catch(reason => this._toastrService.error('', reason.message));
            this._toastrService.success('', 'Reservation updated');
        },);

        if (result.status === ReservationStatuses.Closed) {
            this.propertyService.updateProperty(result.room, {cleanstatus: RoomCleanStatuses.NeedsCleaning});
        }
    }

    addReservation(resdata) {
        this.reservations.add(resdata);
    }

    updateReservation(id, update) {
        this.ReservationsDoc = this._firestore.doc<Reservation>(`${'reservation'}/${id}`);
        this.ReservationsDoc.update(update);
    }

    deleteReservation(id) {
        this.ReservationsDoc = this._firestore.doc<Reservation>(`${'reservation'}/${id}`);
        this.ReservationsDoc.update({status: ReservationStatuses.Deleted});
    }

    createReservationId() {
        return firebase.functions()
            .httpsCallable('createReservationId')
            ()
            .then((result) => {
                return result.data.reservationId;
            });
    }

    checkIfRoomIsAvailable(query) {
        return firebase.functions()
            .httpsCallable('checkIfRoomIsAvailable')
            (query)
            .then((result) => {
                return result.data.roomIsAvailable;
            });
    }
}
