import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/database";
import {AngularFireAuth} from "@angular/fire/auth";
import {take} from "rxjs/operators";
import {AngularFireMessaging} from "@angular/fire/messaging";

@Injectable({
    providedIn: 'root'
})
export class RemindersService {

    currentMessage = new BehaviorSubject(null);

    constructor(
        private _fireDatabase: AngularFireDatabase,
        private _fireAuth: AngularFireAuth,
        private _fireMessaging: AngularFireMessaging) {

    }

    updateToken(token) {
        this._fireAuth.authState.pipe(take(1)).subscribe(
            (user) => {
                const data = {};
                data[user.uid] = token;
                this._fireDatabase.object('fcmTokens/').update(data)
            })
    }

    requestPermission() {
        this._fireMessaging.requestToken
            .subscribe((token) => {
                    this.updateToken(token);
                },
                () => {
                }
            );
    }

    receiveMessage() {
        this._fireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            }, () => {
            })
    }

}
